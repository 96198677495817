import { useLocation } from "@reach/router";
import { useContext, useEffect, useMemo } from "react";
import { navigate } from "gatsby";
import { useSnackbar } from "notistack";
import {
  DEFAULT_MONTHLY_RENT,
  DEFAULT_SIGNUP_BUYING_GOAL,
  EXTERNAL_FINANCE_ADAPTER,
  scenarioTypes,
} from "../commons/enum";
import { useBasiqConsent } from "../hooks";
import { useScenarioList } from "../hooks/scenario.hooks";
import DataLoadingContext from "../context/data-loading-context";
import { useMyInfo } from "../hooks/user.hooks.ts";

const OpenBankingRedirect = () => {
  const { setAppState } = useContext(DataLoadingContext);
  const { data: userInfo = {} } = useMyInfo();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const location = useLocation();
  const obType = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("type");
  }, [location]);

  const appRoute = useMemo(() => {
    const _appRoute = new URLSearchParams(location.search).get("appRoute");
    return _appRoute ? decodeURIComponent(_appRoute) : "/";
  }, [location]);

  const error = useMemo(() => {
    const _err = new URLSearchParams(location.search).get("error");
    return _err ? decodeURIComponent(_err) : null;
  }, [location]);

  const isTimeout = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    return searchParams.get("timeout") === "true";
  }, [location]);

  const {
    data: scenarioList = [],
    isLoading: isLoadingScenarioList,
  } = useScenarioList();
  const firstName = useMemo(() => {
    return userInfo?.firstName || "";
  }, [userInfo]);

  const { handleRedirect } = useBasiqConsent();
  useEffect(() => {
    // currently, only BasicQ is supported
    if (obType !== EXTERNAL_FINANCE_ADAPTER.BasicQ) {
      navigate("/page-error");
      return;
    }

    // wait till the scenario list is fetched
    if (isLoadingScenarioList) return;
    // if there is no scenario, create default scenarios
    if (scenarioList.length <= 0) {
      setAppState({
        pendingToCreateScenarios: [
          {
            buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
            firstName,
          },
          {
            scenarioType: scenarioTypes.BUY_AN_INVESTMENT,
            buyingGoal: DEFAULT_SIGNUP_BUYING_GOAL,
            monthlyRent: DEFAULT_MONTHLY_RENT,
            firstName,
          },
        ],
        shouldCreatePendingScenario: true,
        isNotConnectingToAnyBank: false,
      });
    }
    // warning/error notification but not blocking
    let snackBarErrorKey;
    let snackBarTimeoutKey;
    if (error) {
      snackBarErrorKey = enqueueSnackbar(error, {
        variant: "error",
        persist: true,
      });
      new Promise((resolve) => setTimeout(resolve, 3000)).then(() => {
        closeSnackbar(snackBarErrorKey);
      });
    }
    if (isTimeout) {
      snackBarTimeoutKey = enqueueSnackbar(
        "The progress is taking longer than expected. Please wait if your data is not updated.",
        { variant: "warning", persist: true }
      );
      new Promise((resolve) => setTimeout(resolve, 5000)).then(() => {
        closeSnackbar(snackBarTimeoutKey);
      });
    }

    // navigate to previous page
    handleRedirect(appRoute);
  }, [obType, isLoadingScenarioList]);

  return <></>;
};

export default OpenBankingRedirect;
